import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner';
import { IonAvatar, IonButton, IonButtons, IonChip, IonContent, IonFab, IonFabButton, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonPage, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToggle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { add, alertCircle, arrowBack, arrowDown, arrowUp, checkmarkCircle, checkmarkCircleSharp, closeCircle, ellipseOutline, flag, options, refresh, search, storefront, swapVertical } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { incluirOperacao, requestService } from '../../../../../Utils/Services';
import './Operacoes.css';
import OperacoesList from './OperacoesList';
//import logo from '../../../../../theme/logos/logo_violeta.png'

import validator from 'validator';
import OperacoesFiltroLojas from './OperacoesFiltroLojas';
import OperacoesPesquisar from './OperacoesPesquisar';

type Props = {
  recarregar: boolean;
};

const OperacoesNovo: React.FC<Props> = (props: Props) => {

  const inputRef = useRef<any>(null);
  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [showModalFiltroLojas, setShowModalFiltroLojas] = useState(false);

  const [showModalPesquisar, setShowModalPesquisar] = useState(false);
  const [showModalFiltroOpcoes, setShowModalFiltroOpcoes] = useState(false);
  const [ordenar, setOrdenar] = useState(true);
  const [pesquisa, setPesquisa] = useState<string>();
  const [stateStatusSituacaoLiberacao, setStateSituacaoLiberacao] = useState<boolean>();
  const [stateChipLiberadoSelected, setStateChipLiberadoSelected] = useState<boolean>(true);
  const [stateChipEmLiberacaoSelected, setStateChipEmLiberacaoSelected] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [statusOperacao, setStatusOperacao] = useState<string>('Em');

  const [showModalTipoOperacao, setShowModalTipoOperacao] = useState(false);
  const [codigoBarras, setCodigoBarras] = React.useState('0');
  const [lojaCadastro, setLojaCadastro] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.clienteLojaId || "");
  const [tipoOperacao, setTipoOperacao] = useState("Entrega");
  const [tipoCarga, setTipoCarga] = useState(window.localStorage.getItem("tipoCarga") || "");
  const [cpf, setCPF] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [placa, setPlaca] = useState("");

  const [showModalCodigoBarrasDigitar, setShowModalCodigoBarrasDigitar] = useState(false);

  const [lojas, setLojas] = useState<string[]>(JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (valor.clienteLojaId)));

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewWillEnter(() => { setPesquisa(""); setShowModalPesquisar(false); })

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";
    if (window.localStorage.getItem("reloadOperacoes") == "sim") {
      window.localStorage.setItem("reloadOperacoes", "não");
      setShowLoading(!showLoading);
    }
  });

  function onChangeCPF(e: any) {
    e.persist();
    e.preventDefault();

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    // console.log(cpfval);
    setCPF(cpfval);
  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    let replaceAt = function (str: any, index: any, replacement: any) {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length);
    }

    let form = "(__) _____-____";

    let val = e.target.value;

    let i = 1;
    if (telefone.length > 0) {
      i = 0;
    }
    let j = 0;
    let aumentou = false;

    if (val.length > telefone.length) {

      for (; i < form.length; i++) {
        if (j < val.length) {

          form = replaceAt(form, i, val.charAt(j))

          if (form.charAt(i + 1) === '(' || form.charAt(i + 1) === ')' || form.charAt(i + 1) === '-') {
            if (val.charAt(j + 1) !== form.charAt(i + 1)) {
              if (form.charAt(i + 1) === ')') {
                i++;
              }
              i++;
              aumentou = true;
              j++;
            } else {
              j++
            }
          } else {
            j++;
          }
        } else {
          break;
        }
      }
    } else {
      if (telefone.length === 1 || telefone.length === 11) {
        form = val.substr(0, val.length - 1);
      } else if (telefone.length === 5) {
        form = val.substr(0, val.length - 2);
      } else {
        form = val;
      }
      i = form.length;
    }
    setTelefone(form.substr(0, i));
  }

  const openScanner = async () => {

    const opt: BarcodeScannerOptions = {
      formats: 'CODE_128',
      prompt: 'Escaneie o codigo de barras'
    }

    const data: any = await BarcodeScanner.scan(opt);

    if (data !== null) {
      setCodigoBarras(data.text.match(/(.{1,4})/g).join(" "));
      setShowModalCodigoBarrasDigitar(true);
    }

  };

  function validarCampos() {
    if (nome.length < 3 || cpf.length != 14 || telefone.length != 15 || placa.length === 0) {
      setTextToast("Todos os campos são obrigatórios.");
      setShowToast(true);
      return false;
    }
    else {
      if (!validarCpfNovo(cpf)) {
        setTextToast("CPF Inválido.");
        setShowToast(true);
      }
      else if (!validator.isMobilePhone(telefone, 'pt-BR')) {
        setTextToast("Celular Inválido.");
        setShowToast(true);
      }
      else if (placa.length != 7) {
        setTextToast("Placa Inválida.");
        setShowToast(true);
      }
      else {
        return true;
      }
    }
  }

  function validarCpfNovo(val: any): boolean {
    if (val.length == 14) {
      var cpf = val.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = ((v1 * 10) % 11);

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = ((v2 * 10) % 11);

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    } else { return false; }
  }

  function iniciarOperacao() {
    if (!validarCampos())
      return;

    if (codigoBarras === undefined || codigoBarras.replace(/\ /, '') === '') {
      setAlertaPreenchimento(true);
    }
    else {
      if (botaoAtivo) {
        setBotaoAtivo(false);
        setShowModalCodigoBarrasDigitar(false);

        let objRequest = {
          "motoristaNome": nome,
          "motoristaDocumento": cpf || "",
          "motoristaTelefone": telefone || "",
          "motoristaPlaca": placa || "",
          "tipoOperacao": tipoOperacao,
          "tipoCarga": tipoCarga,
          "chaveAcesso": codigoBarras.replace(/ /g, ""),
          "loja": (lojaCadastro && lojaCadastro != "") ? lojaCadastro : ""
        };

        requestService(incluirOperacao.url, {
          method: incluirOperacao.method,
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          body: JSON.stringify(objRequest),
          credentials: 'include'
        },
          history,
          (response: any) => {
            if (response.message) {
              setBotaoAtivo(true);
              setShowLoading(!showLoading);
              setShowModalCodigoBarrasDigitar(false);
              setShowModalTipoOperacao(false);
              //carregarOperacoesSegmentada(statusOperacao);
              setTextToast("Operação criada com sucesso!");
              setShowToast(true);
            }
          },
          (error: any) => {
            setTextToast("Erro ao tentar inserir! Tente novamente!");
            setShowToast(true);
            setBotaoAtivo(true);
          })

      }
    }
  }

  function cancelarScan() {
    setShowModalCodigoBarrasDigitar(false);
    //setShowModalCodigoBarras(false);
  }

  function space(str: any, after: any) {
    setAlertaPreenchimento(false);

    if (!str) {
      setCodigoBarras('');
      return false;
    }
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
      reg = new RegExp(".{" + after + "}", "g");
    setCodigoBarras(
      v.replace(reg, function (a: any) {
        return a + ' ';
      })
    );
  };

  function hideModalPesquisar() {
    setPesquisa("");
    setShowModalPesquisar(false);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons hidden={true} slot="start">
            <IonButton>
              <IonIcon slot="icon-only" icon={flag} />
            </IonButton>
          </IonButtons>
          <IonAvatar slot='start' style={{ marginLeft: '10px', width:'48px', height:'48px' }}>
            <img src={window.localStorage.getItem("logo") ? "/assets/logos/" + window.localStorage.getItem("logo") + ".png" : "/assets/logos/saidamax.png"} />
          </IonAvatar>
          <IonTitle style={{paddingLeft:'12px'}}>Operações</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setOrdenar(!ordenar)} hidden={true || statusOperacao != 'Em'}>
              <IonIcon slot="icon-only" icon={swapVertical}></IonIcon>
            </IonButton>
            <IonButton onClick={() => { setPesquisa(""); setShowModalPesquisar(!showModalPesquisar) }} color={showModalPesquisar ? 'danger' : 'dark'} hidden={statusOperacao != 'Em'}>
              <IonIcon slot="icon-only" icon={search} />
            </IonButton>
            <IonButton onClick={() => { setPesquisa(""); setShowModalFiltroOpcoes(!showModalFiltroOpcoes) }} color={showModalFiltroOpcoes ? 'danger' : 'danger'} hidden={statusOperacao != 'Em'}>
              <IonIcon slot="icon-only" icon={options} />
            </IonButton>
            <IonButton hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true} onClick={() => setShowModalFiltroLojas(true)} >
              <IonIcon slot="icon-only" icon={storefront} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar color="primary" hidden={!showModalPesquisar}>
          <IonSearchbar debounce={700} value={pesquisa} onIonClear={(e) => setPesquisa("")} onIonChange={(e) => { setPesquisa(e.detail.value) }} placeholder='Pesquise pelas notas que estão aparecendo nesta tela' ></IonSearchbar>
        </IonToolbar>
        <IonToolbar hidden={!showModalFiltroOpcoes}>
          <IonItem lines='full' color='primary'>
            <IonLabel style={{ background: '#fff', padding: '10px', borderRadius: '2.5px', boxShadow: '0px 1px 3px gray' }}>
              <p style={{ marginBottom: '5px', color: '#000' }}><b>Filtros</b></p>
              <p style={{ width: 'auto', marginTop: '5px', color: '#333', borderBottom: '0px solid rgba(0,0,0,0.5)' }}>Situação da Liberação</p>
              <IonChip onClick={() => { setStateChipEmLiberacaoSelected(!stateChipEmLiberacaoSelected); }} outline={true} style={{ marginLeft: '0px' }}><IonIcon icon={stateChipEmLiberacaoSelected ? checkmarkCircleSharp : ellipseOutline} style={{ color: 'black' }}></IonIcon><IonLabel>Em liberação</IonLabel></IonChip>
              <IonChip onClick={() => { setStateChipLiberadoSelected(!stateChipLiberadoSelected); }} outline={true}><IonIcon icon={stateChipLiberadoSelected ? checkmarkCircleSharp : ellipseOutline} style={{ color: 'black' }}></IonIcon><IonLabel>Liberado</IonLabel></IonChip>
            </IonLabel>
          </IonItem>
        </IonToolbar>
        <IonToolbar>
          <IonSegment value={statusOperacao} onIonChange={(e) => { /*setStatusOperacao(e.detail.value || 'Em');*/ }} >
            <IonSegmentButton value="Em" layout="icon-end" onClick={(e) => { if (statusOperacao == 'Em') { hideModalPesquisar(); setOrdenar(!ordenar) } else { setStatusOperacao('Em') } }} >
              <IonLabel>Andamento</IonLabel>
              <IonIcon color='medium' icon={ordenar ? arrowDown : arrowUp} hidden={statusOperacao != 'Em'} ></IonIcon>
            </IonSegmentButton>
            <IonSegmentButton value="Concluido" onClick={(e) => { setShowModalPesquisar(false); if (statusOperacao != 'Concluido') { setStatusOperacao('Concluido'); } hideModalPesquisar(); }}>
              <IonLabel>Concluídos</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen scrollY={false} scrollEvents={true} ref={contentRef} >
        <IonFab vertical="top" horizontal="end" slot="fixed" edge >
          <IonFabButton color="tertiary" onClick={() => {
            setCPF("");
            setNome("");
            setTelefone("");
            setPlaca("");
            setShowModalTipoOperacao(true);
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonFab vertical="top" horizontal="start" slot="fixed" edge >
          <IonFabButton color="tertiary" onClick={() => { setShowLoading(!showLoading); }} >
            <IonIcon color="light" icon={refresh}></IonIcon>
          </IonFabButton>
        </IonFab>

        <OperacoesList filtro={statusOperacao} lojas={Buffer.from(JSON.stringify(lojas)).toString('base64')} reload={showLoading} router={history} usaLiberacao={true} ordenar={ordenar} pesquisa={pesquisa} chipEmLiberacao={stateChipEmLiberacaoSelected} chipLiberado={stateChipLiberadoSelected} />

        <IonModal isOpen={showModalFiltroLojas} onDidDismiss={() => setShowModalFiltroLojas(false)}>
          <OperacoesFiltroLojas lojas={lojas} setLojas={setLojas} setShowModalFiltroLojas={setShowModalFiltroLojas} />
        </IonModal>

        <IonModal isOpen={false} onDidDismiss={() => setShowModalPesquisar(false)}>
          <OperacoesPesquisar setShowModalPesquisar={setShowModalPesquisar} router={history} />
        </IonModal>

        <div style={{ height: '200px' }}></div>

        <div className="content">

          <IonModal isOpen={showModalCodigoBarrasDigitar} onDidPresent={() => { inputRef.current.setFocus(); }} onDidDismiss={() => { setCodigoBarras('0'); setShowModalCodigoBarrasDigitar(false) }} onWillPresent={() => { setCodigoBarras(''); }} >
            <IonHeader>
              <IonToolbar color="primary">
                <IonTitle>Informe a Nota Fiscal</IonTitle>
                <IonButtons slot="start" onClick={() => { cancelarScan(); setShowModalCodigoBarrasDigitar(false); }}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">
              <IonItem lines="none">
                <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
                <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "fontSize": "14px" }} >Campo obrigatório</IonLabel>
              </IonItem>
              <IonItem lines="none">
                <IonLabel className="ion-text-wrap" position="stacked">Informe a <b>chave de acesso</b> (código de barras) na <b>nota</b>:</IonLabel>
                <IonTextarea ref={(ref) => inputRef.current = ref} rows={3} inputMode="numeric" maxlength={54} value={codigoBarras} placeholder="clique aqui para digitar ou utilize o leitor de código de barras" onIonChange={e => space(e.detail.value!, 4)}></IonTextarea>
              </IonItem>
            </IonContent>
            <IonFooter className="ion-no-border" >
              <IonToolbar>
                <IonButton expand="block" size='large' disabled={!botaoAtivo} onClick={() => { iniciarOperacao() }}>Iniciar Operação!</IonButton>
                <IonButton expand="block" fill="clear" color="tertiary" onClick={() => { openScanner() }}>Utilizar câmera do celular</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>

          <IonModal isOpen={showModalTipoOperacao} onDidDismiss={() => setShowModalTipoOperacao(false)} onWillPresent={() => { setCodigoBarras('0'); }} >
            <IonHeader>
              <IonToolbar color="primary">
                <IonTitle>Cadastro de Operação</IonTitle>
                <IonButtons slot="start" onClick={() => setShowModalTipoOperacao(false)}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">
              <IonList lines="full" class="ion-no-margin">
                <IonListHeader lines="full">
                  <IonLabel>
                    Dados da Operação
                  </IonLabel>
                </IonListHeader>
                <IonItem lines="none" hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true}>
                  <IonLabel>Loja</IonLabel>
                  <IonSelect name='lojaCadastro' placeholder='Selecione a loja' title='Lojas' value={lojaCadastro} multiple={false} cancelText="Cancel" okText="Ok" onIonChange={e => { setLojaCadastro(e.detail.value); }} slot="end">
                    {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                      <IonSelectOption value={valor.clienteLojaId}>{valor.clienteLojaNome}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>Tipo de operação</IonLabel>
                  <IonSelect value={tipoOperacao} onIonChange={(e) => { setTipoOperacao(e.detail.value || "") }}>
                    <IonSelectOption value="Entrega">Entrega</IonSelectOption>
                    <IonSelectOption value="Coleta">Coleta</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>Carga</IonLabel>
                  <IonSelect value={tipoCarga} onIonChange={(e) => { window.localStorage.setItem("tipoCarga", e.detail.value || ""); setTipoCarga(e.detail.value || ""); }}>
                    <IonSelectOption value="Seca">Seca</IonSelectOption>
                    <IonSelectOption value="Perecível">Perecível</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem hidden={true} lines="none">
                  <IonLabel>Carga Paletizada</IonLabel>
                  <IonToggle checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
                </IonItem>
              </IonList>

              <IonList lines="full" class="ion-no-margin">
                <IonListHeader lines="full">
                  <IonLabel>
                    Informações do Motorista
                  </IonLabel>
                </IonListHeader>
                <IonItem lines="none">
                  <IonLabel>Nome*</IonLabel>
                  <IonInput placeholder="Nome do motorista" value={nome} required onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel >CPF*</IonLabel>
                  <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel >Celular*</IonLabel>
                  <IonInput type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel >Veículo*</IonLabel>
                  <IonInput placeholder="Digite a placa do veículo" value={placa} required maxlength={7} onIonInput={(e: any) => setPlaca(e.target.value)}></IonInput>
                </IonItem>
              </IonList>
            </IonContent>
            <IonFooter className="ion-no-border" >
              <IonToolbar>
                <IonButton expand="block" size='large' color="primary" onClick={() => { if (!validarCampos()) { return; }; if (tipoOperacao === "Entrega") { setShowModalCodigoBarrasDigitar(true); } else { iniciarOperacao(); } }}>Registrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>


        </div>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={1000}
        />

        {/*
        <IonRefresher slot="fixed" onIonRefresh={(e: any) => { setShowLoading(!showLoading); setTimeout(() => { e.detail.complete(); }, 1000); }}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
         */}

      </IonContent>
    </IonPage >
  );
};

export default OperacoesNovo;


